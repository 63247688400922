import { logger } from "../logger";

export type Stats = {
  record: (name: string, value: number) => void;
  dispose: () => void;
};

const createStats = () => {
  let samples: { [name: string]: number[] } = {};

  const record = (values: { [name: string]: number }) =>
    Object.entries(values).forEach(([name, value]) => {
      samples[name] = samples[name] ?? [];
      samples[name]?.push(value);
    });

  const post = () => {
    logger.debug(
      "Stats",
      Object.fromEntries(
        Object.entries(samples).map(([name, samples]) => [
          name,
          samples.reduce((a, b) => a + b) / samples.length
        ])
      )
    );
    samples = {};
  };

  const interval = setInterval(post, 5000);

  const dispose = () => clearInterval(interval);

  return {
    record,
    dispose
  };
};

export const stats = createStats();
