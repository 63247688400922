import { delay, fork, range } from "@kha/common";
import type { IDBPTransaction } from "idb";
import { openDB } from "idb";
import { v4 as uuid } from "uuid";

export const newRelicApiKey = "fd0c9de957169bfadf7ceb2849bf3ff4FFFFNRAL";

export const createLogger = ({
  enable,
  env,
  app,
  version,
  context
}: {
  enable: boolean;
  env: string;
  app: string;
  version: string;
  context: object;
}) => {
  const instance = uuid({
    // The default requires crypto.getRandomValues which doesn't work everywhere
    random: range(0, 16).map(() => Math.floor(Math.random() * 256))
  });

  const logger = (parent?: () => object) => {
    const log =
      (level: "debug" | "info" | "warn" | "error") =>
      (message: string, data?: unknown) => {
        if (data || parent)
          console[level](
            message,
            data instanceof Error
              ? data
              : { ...parent?.(), ...(typeof data === "object" ? data : {}) }
          );
        else console.log(message);

        if (enable) {
          const timestamp = Date.now();

          void post({
            timestamp,
            level,
            message,
            env,
            app,
            version,
            instance,
            ...parent?.(),
            ...context,
            ...(data instanceof Error
              ? { error: data.message, stack: data.stack }
              : typeof data === "object"
                ? data
                : {})
          });
        }
      };

    return {
      debug: log("debug"),
      info: log("info"),
      warn: log("warn"),
      error: log("error"),
      child: (data: object | (() => object)) =>
        logger(() => ({
          ...parent?.(),
          ...(typeof data === "function" ? (data() as object) : data)
        })),
      set enable(_: boolean) {
        enable = _;
      }
    };
  };

  return logger();
};

export type Logger = ReturnType<typeof createLogger>;

const databaseEnabled = typeof window !== "undefined";

const post = async (event: object) => {
  if (databaseEnabled && !navigator.onLine) await store(event);
  else await sendNewRelic(event);
};

const store = async (event: object) => {
  await transact(async transaction => {
    const logs = transaction.objectStore("logs");
    await logs.put(event);
  });
};

const drain = async () =>
  await transact(async transaction => {
    const logs = transaction.objectStore("logs");
    const entries = await logs.getAll(null, 100);
    await Promise.all(entries.map(({ id }) => logs.delete(id)));
    return entries;
  });

const transact = async <T>(
  f: (_: IDBPTransaction<unknown, string[], "readwrite">) => Promise<T>
) => {
  const database = await openDB("logs", 1, {
    upgrade: database =>
      database.createObjectStore("logs", {
        keyPath: "id",
        autoIncrement: true
      })
  });
  const transaction = database.transaction(["logs"], "readwrite");
  const result = await f(transaction);
  await transaction.done;
  return result;
};

const publish = async () => {
  while (true) {
    const entries = await drain();
    if (!entries.length) return;
    await Promise.all(entries.map(post));
  }
};

if (databaseEnabled)
  fork(async () => {
    while (true) {
      if (navigator.onLine) await publish();
      await delay(10000);
    }
  });

const sendNewRelic = async (event: object) => {
  for (let i = 0; i < 7; i++)
    try {
      if (typeof navigator !== "undefined" && !navigator.onLine) return;

      await fetch(
        `https://log-api.newrelic.com/log/v1?Api-Key=${newRelicApiKey}`,
        {
          method: "POST",
          body: JSON.stringify(event)
        }
      );

      return;
    } catch (error) {
      await delay(100 * Math.pow(2, i));
    }
};
